/*

Theme: Purpose - Website UI Kit
Version: 2.1.0
Product Page: https://themes.getbootstrap.com/product/purpose-website-ui-kit/
License: https://themes.getbootstrap.com/licenses/
Author: Webpixels
Author URI: https://www.webpixels.io

---

Copyright 2019 Webpixels

*/
